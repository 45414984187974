export default [
    {
        path: '/crud_service',
        name: 'CrudService',
        component: () => import('./CrudService.vue')
    },
    {
        path: '/crud_service/tambah',
        name: 'CrudServiceCreate',
        component: () => import('./Create.vue')
    },
    {
        path: '/crud_service/edit/:id',
        name: 'CrudServiceEdit',
        component: () => import('./Edit.vue')
    }
]